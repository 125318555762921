import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  RespBkMthdMapp
} from '../function_lib/common_lib';
import {CallCMSMapMethod_CBack,btnSetColorCMS_CBack,appGetLoanUrlPath} from '../function_lib/common_cms';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

let loginUser="";
let userObject="";
let companyName="";
let clsCmsData;

let rf="0";

let totalFileAllow=8;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let _fullUpdPath="";

class List_Nomination_Req extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stCmsData:[],stMenuList:[],stAllCompList:[],stEditData:{},
      stBNode:"",stPNode:"",stEditData:{},stHeaderEditData:[],stExMenuId:"0"};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsCmsData=this;
          _fullUpdPath=appGetLoanUrlPath();
      }
      resetFilters(){
        $("#ser_text").val("");
        clsCmsData.getCmsData();
      }
      okSuccMapping(data,msg){
        alert(data);
      }

      getNominationReq(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getNominationReq"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        
        map.push({name:"ser_menu",value:$("#ser_menu").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        
       
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getNominationReq";
        CallCMSMapMethod_CBack(clsCmsData,map,function(data,msg){
         //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                clsCmsData.setState({stCmsData:listData},()=>{
                  clsCmsData.forceUpdateHandler();
                });
              }
        });
      }

   


      
componentDidMount(){
  $(".cleditor").summernote();    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
 this.getNominationReq();
// this.getMenuList();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">


<div className='col-lg-3 col-md-3 col-sm-3 float-right'>
<div className="form-group">
         <div className="input-group">
            <select class="form-control form-control-sm" id="ser_menu" name="ser_menu">
                <option value={"0"}>-select-</option>
                {this.state.stMenuList.map((item,index)=>{
                    return <option value={btoa(item.id)}>{item.col1}</option>
                })}

            </select>
             </div>
         </div>
</div>


<div className='col-lg-3 col-md-3 col-sm-3 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" rf="#btnCatSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search by title' />
             </div>
         
         </div>

</div>

<div className="col-lg-4 col-md-4 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
                 <div onClick={()=>this.getCmsData()}  style={{marginRight:"10px"}} id="btnCatSearch" name="btnCatSearch" className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Reset</div>
</div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Nomination List</b></h3>
                
                {/* option of page sections */}
                {/* <div class="input-group-prepend float-right">
                    <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                      Create New
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">New Menu</a>
                      <a class="dropdown-item" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">New Section to Menu</a>
                      <a class="dropdown-item" data-toggle="modal" data-target="#divCmsAddNew" href="javascript:void(0)">Add New Data</a>
                    </div>
                  </div> */}
                {/* end of page sections */}

              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Company Name</th>
                      <th>Business Activity</th>
                      <th>Person Name</th>
                      <th>Job Title</th>
                      <th>EmailId</th>
                      <th>Contact No</th>
                      <th>Achievements</th>
                      <th>Form Cat</th>
                      <th>Sub Cat</th>
                      <th>Req. Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stCmsData.map((item,index)=>{
                    //alert(item.col12)
                       return(<tr>
                        <td>{index+1}</td>
                    
                  <td>{item.col1}</td>
                  <td>{item.col3}</td>
                  <td>{item.col4}</td>
                  <td>{item.col5}</td>
                  <td>{item.col6}</td>
                  <td>{item.col7}</td>
                  <td>{item.col9}</td>
                  <td>{item.col10}</td>
                  <td>{item.col11}</td>
                  <td>{item.entry_date}</td>
                  <td>
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                      Action
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" download href={item.col12!==""?_fullUpdPath+item.col12:'javascript:void(0)'}>{item.col12!==""?"Download":'No form updated'}</a>
                     
                    </div>
                  </div>
                  </td>
                  
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
  </div>)
      }
}
export default List_Nomination_Req;