import $ from 'jquery';
import React,{useEffect} from 'react';
import {GetImgCertUserCMS_CBack,appGetUrlPath} from '../function_lib/common_cms';

export function editCMSContent(){
  GetImgCertUserCMS_CBack(this,"#frmCmsEditNew","cmsEditUserEntryNew",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        alert(checkMsg);
        $('#divCmsEditNew').modal('hide');
        //window.location.reload();
      }
    });
}             
  
export const CMS_Edit_Data=(props)=>{
  let _imgPath=appGetUrlPath();
  let proMainImages=[];
  let proOthImages=[];
  // useEffect(()=>{
   
  // },[props.editData]);

  $("#edit_col2").summernote('code', props.editData.col2);
  $("#edit_col3").summernote('code', props.editData.col3);
  //alert(JSON.stringify(props.editData));
  

  
  if(typeof props.editData.col4!=="undefined" && props.editData.col4.trim()!==""){
      proMainImages=props.editData.col4.split(',');
  }

  
  if(typeof props.editData.col5!=="undefined" && props.editData.col5.trim()!==""){
      proOthImages=props.editData.col5.split(',');
  }
   


    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divCmsEditNew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form class="form-group" id="frmCmsEditNew" name="frmCmsEditNew" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="cmsEditUserEntryNew" />
                            <input type="hidden" name="m_type" id="m_type" value="cmsEditUserEntryNew" />
                            {/* <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} /> */}
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            <input type="hidden"  name="edit_lsImages" id="edit_lsImages" />
                            <input type="hidden"  name="edit_lsOthImages" id="edit_lsOthImages"/>
                            <input type="hidden" name="bNode" id="bNode" value={props.b_node}/>
                            <input type="hidden" name="pNode" id="pNode" value={props.p_node}/>
                            <input type="hidden" name="col20" id="col20" value={props.p_node}/>
                            <input type="hidden" name="type" id="type" value={"20"}/>
                            <input type="hidden" name="edit_id" id="edit_id" value={btoa(props.editData.content_id)}/>
                          
                            <div class="card-body">
                                <div className="row">
                                <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Title</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col1" name="col1" className="form-control required11" placeholder="" defaultValue={props.editData.col1} />
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>
                                <div class="row">
                                 
    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 1 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="edit_col2" name="edit_col2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 2 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="edit_col3" name="edit_col3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* existing images slot */}
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                            <div className="row">
      {proMainImages.map((item,index)=>{
        if(item.trim()!==""){
            return (<div id={"edit_main_img"+index} className="col-md-4 image_thumb_parent">
              <a href="javascript:void(0)" className="_setbackparam" param3={"edit_main_img"+index} param1={item} param2={btoa(props.editData.content_id)} resback={"onDelMainImage"}><i className="fa fa-close icon-close"></i></a>
            <img className="img-responsive" src={_imgPath+item} />
        </div>)
        }
       
      })}
    </div>
                                             </div>   

<div className="col-md-8">
<div className="row">
      {proOthImages.map((item,index)=>{
        if(item.trim()!==""){
            return (<div id={"edit_oth_image"+index} className="col-md-4 image_thumb_parent">
              <a href="javascript:void(0)" className="_setbackparam" param3={"edit_oth_image"+index} param1={item} param2={btoa(props.editData.content_id)} resback={"onDelOthImage"}><i className="fa fa-close icon-close"></i></a>
            <img className="img-responsive" src={_imgPath+item} />
        </div>)
        }
       
      })}
    </div>
</div>
                                        </div>

                                    </div>
                                    {/* end of existing images slot */}

{/* image upload section */}
<div className="col-md-12">
<div className="row">
{/* file image upload */}

<div className="col-md-4">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Main Image </h3><small> (Max File Size 1MB, Only 1 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb eximgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew"  thumbnail="eximgLibThumb" lsElement="edit_lsImages" name="editCustomFileBr" id="editCustomFileBr" accept="image/*" size={10000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="editCustomFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of file image upload */}

                               {/* file other image upload */}

<div className="col-md-8">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Other Images </h3><small> (Max File Size 1MB, Only 7 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb eximgOthLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew" thumbnail="eximgOthLibThumb" lsElement="edit_lsOthImages" multiple name="excustomOthFileBr" id="excustomOthFileBr" accept="image/*" size={10000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="excustomOthFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of other file image upload */}
</div>
</div>
{/* end of Image Upload Section */}



                                    <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Video Links</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col6" name="col6" className="form-control required11" placeholder="" defaultValue={props.editData.col6} />
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Page Url</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col7" name="col7" className="form-control required11" placeholder="" defaultValue={props.editData.col7} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Url Text</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col8" name="col8" className="form-control required11" placeholder="" defaultValue={props.editData.col8} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Keywords</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col9" name="col9" className="form-control required11" placeholder="" defaultValue={props.editData.col9} />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Only for non-Slider Components</label>
                                            <div className="input-group input-group-sm">
                                              <select id="col10" name="col10" className="form-control">
                                                <option value={""}>-select-</option>
                                                <option selected={props.editData.col10==="tab"} value={"tab"}>Tab</option>
                                                <option selected={props.editData.col10==="round-image"} value={"round-image"}>Round Image</option>
                                                <option selected={props.editData.col10==="tab_content"} value={"tab_content"}>Tab Content</option>
                                                <option selected={props.editData.col10==="top_text"} value={"top_text"}>Set Top Text</option>
                                              </select>
                                                
                                            </div>
                                        </div>
                                      </div>  
                                        <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Adjust Container</label>
                                            <div className="input-group input-group-sm">
                                              <select id="col11" name="col11" className="form-control">
                                                <option value={""}>-select-</option>
                                                <option selected={props.editData.col11==="container"} value={"container"}>Container</option>
                                                <option selected={props.editData.col11==="container-sm"} value={"container-sm"}>Small Container</option>
                                                <option selected={props.editData.col11==="full_screen_length"} value={"full_screen_length"}>Full Screen Length</option>
                                              </select>
                                                
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Adjust Background</label>
                                            <div className="input-group input-group-sm">
                                              <select id="col12" name="col12" className="form-control">
                                                <option value={""}>-select-</option>
                                                <option selected={props.editData.col12==="default-image"} value={"default-image"}>Default Image</option>
                                                
                                                <option selected={props.editData.col12==="bg-gray"} value={"bg-gray"}>Gray</option>
                                                <option selected={props.editData.col12==="Bg-Img-Left"} value={"Bg-Img-Left"}>Bg-Img-Left</option>
                                                <option selected={props.editData.col12==="Bg-Img-Right"} value={"Bg-Img-Right"}>Bg-Img-Right</option>
                                                <option selected={props.editData.col12==="Bg-Img-4"} value={"Bg-Img-4"}>Bg-Img-4</option>
                                                <option selected={props.editData.col12==="Bg-Img-3"} value={"Bg-Img-3"}>Bg-Img-3</option>
                                              </select>
                                                
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Adjust Row Items</label>
                                            <div className="input-group input-group-sm">
                                              <select id="col13" name="col13" className="form-control">
                                                <option value={""}>-select-</option>
                                                <option selected={props.editData.col13==="col-md-3 col-sm-6"} value={"col-md-3 col-sm-6"}>Row-4</option>
                                                <option selected={props.editData.col13==="col-md-4 col-sm-6"} value={"col-md-4 col-sm-6"}>Row-3</option>
                                                <option selected={props.editData.col13==="col-md-6 col-sm-6"} value={"col-md-6 col-sm-6"}>Row-2</option>
                                                <option selected={props.editData.col13==="one-element"} value={"one-element"}>one-element</option>
                                              </select>
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Priority Index</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" id="col14" name="col14" className="form-control required11" defaultValue={props.editData.col14} />
                                            </div>
                                        </div>
                                  </div>


                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>editCMSContent()} class="btn btn-primary">Submit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default CMS_Edit_Data;
