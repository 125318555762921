import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

let jQuery=$;

//const appUrl="http://localhost:8086/dhanvantari_admin/";
//const appUrtPath="http://localhost:8086/dhanvantari_admin/upd/";
let _cmsAppUrl="https://cms.fastrcdirect.com/controller/pgControl.php?pm=";
let _cmsAppUrtPath="https://cms.fastrcdirect.com/fastrc_upd/";
let _cmsAppLoanUrtPath="https://cms.fastrcdirect.com/fastrc_upd/";


export const _cmsSetInitParam=()=>{
  //alert("i m in check");
  let checkHost=window.location.hostname;
  if(checkHost==="adnew.fastrcdirect.com"){
    
    _cmsAppUrl="https://cms.fastrcdirect.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.fastrcdirect.com/fastrc_upd/";
    _cmsAppLoanUrtPath="https://cms.fastrcdirect.com/fastrc_upd/";

  }else if(checkHost==="adnew.mahamayasweets.com"){

    _cmsAppUrl="https://cms.mahamayasweets.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.mahamayasweets.com/mahamaya_upd/";
    _cmsAppLoanUrtPath="https://cms.mahamayasweets.com/mahamaya_upd/";

  }else if(checkHost==="adnew.1040-prep.com"){
    _cmsAppUrl="https://cms.1040-prep.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.1040-prep.com/1040_prep_upd/";
    _cmsAppLoanUrtPath="https://cms.1040-prep.com/1040_prep_upd/";
  }else if(checkHost==="astrocms.astrosaxena.com"){
    _cmsAppUrl="https://astrosaxena.com/process/call_auth.php?pm=";
    _cmsAppUrtPath="https://astrosaxena.com/";
    _cmsAppLoanUrtPath="https://astrosaxena.com/control/gallery/";
  }else if(checkHost==="adnew.ayusharogyam.com"){
    _cmsAppUrl="https://cms.ayusharogyam.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.ayusharogyam.com/ayush_upd/";
    _cmsAppLoanUrtPath="https://cms.ayusharogyam.com/ayush_upd/";
  }else if(checkHost==="adminmui.ashtechnologies.in"){
    _cmsAppUrl="https://muidata.ashtechnologies.in./controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://muidata.ashtechnologies.in./mui_upd/";
    _cmsAppLoanUrtPath="https://muidata.ashtechnologies.in./mui_upd/";
  }else if(checkHost==="adnew.bhandarkarpublications.com"){
    _cmsAppUrl="https://cms.bhandarkarpublications.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.bhandarkarpublications.com/bhandarkar_web_upd/";
    _cmsAppLoanUrtPath="https://cms.bhandarkarpublications.com/bhandarkar_web_upd/";
  }else if(checkHost==="adnew.mysticmansha.com"){
    _cmsAppUrl="https://cms.mysticmansha.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.mysticmansha.com/mystic_man_upd/";
    _cmsAppLoanUrtPath="https://cms.mysticmansha.com/mystic_man_upd/";
  }else if (checkHost==="adnew.rockenginesindia.com"){
    _cmsAppUrl="https://cms.rockenginesindia.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.rockenginesindia.com/rockengines_upd/";
    _cmsAppLoanUrtPath="https://cms.rockenginesindia.com/rockengines_upd/";
  }
  else{
     //for Society
    //  _cmsAppUrl="https://cms.societyofglobalcycle.com/controller/pgControl.php?pm=";
    //  _cmsAppUrtPath="https://cms.societyofglobalcycle.com/soci_cycle_upd/";
    //  _cmsAppLoanUrtPath="https://cms.societyofglobalcycle.com/soci_cycle_upd/";

    //for ManshaMishra
    // _cmsAppUrl="https://cms.mysticmansha.com/controller/pgControl.php?pm=";
    // _cmsAppUrtPath="https://cms.mysticmansha.com/mystic_man_upd/";
    // _cmsAppLoanUrtPath="https://cms.mysticmansha.com/mystic_man_upd/";

    //for AyushArogyam
    // _cmsAppUrl="https://cms.ayusharogyam.com/controller/pgControl.php?pm=";
    // _cmsAppUrtPath="https://cms.ayusharogyam.com/ayush_upd/";
    // _cmsAppLoanUrtPath="https://cms.ayusharogyam.com/ayush_upd/";
    
    //for RockEngines
    // _cmsAppUrl="https://cms.rockenginesindia.com/controller/pgControl.php?pm=";
    // _cmsAppUrtPath="https://cms.rockenginesindia.com/rockengines_upd/";
    // _cmsAppLoanUrtPath="https://cms.rockenginesindia.com/rockengines_upd/";
    


    //for astrocms
    // _cmsAppUrl="https://astrosaxena.com/process/call_auth.php?pm=";
    // _cmsAppUrtPath="https://astrosaxena.com/";
    // _cmsAppLoanUrtPath="https://astrosaxena.com/control/gallery/";

    // _cmsAppUrl="http://localhost:8086/astro_new/process/call_auth.php?pm=";
    // _cmsAppUrtPath="http://localhost:8086/astro_new/";
    // _cmsAppLoanUrtPath="http://localhost:8086/astro_new/control/gallery/";
    
    // for bhandarkar main web
    _cmsAppUrl="https://cms.bhandarkarpublications.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.bhandarkarpublications.com/bhandarkar_web_upd/";
    _cmsAppLoanUrtPath="https://cms.bhandarkarpublications.com/bhandarkar_web_upd/";
    
    //for default dev call
    // _cmsAppUrl="https://muidata.ashtechnologies.in./controller/pgControl.php?pm=";
    // _cmsAppUrtPath="https://muidata.ashtechnologies.in./mui_upd/";
    // _cmsAppLoanUrtPath="https://muidata.ashtechnologies.in./mui_upd/";
    
    
    //  _cmsAppUrl="https://cms.fastrcdirect.com/controller/pgControl.php?pm=";
    //  _cmsAppUrtPath="https://cms.fastrcdirect.com/fastrc_upd/";
    //  _cmsAppLoanUrtPath="https://cms.fastrcdirect.com/fastrc_upd/";
     
    
    // _cmsAppUrl="https://cms.mahamayasweets.com/controller/pgControl.php?pm=";
    // _cmsAppUrtPath="https://cms.mahamayasweets.com/mahamaya_upd/";
    // _cmsAppLoanUrtPath="https://cms.mahamayasweets.com/mahamaya_upd/";
    
    
   /*
    _cmsAppUrl="https://cms.1040-prep.com/controller/pgControl.php?pm=";
    _cmsAppUrtPath="https://cms.1040-prep.com/1040_prep_upd/";
    _cmsAppLoanUrtPath="https://cms.1040-prep.com/1040_prep_upd/";
    */
  }
  
}

_cmsSetInitParam();

export const _cmsGetInitParam=()=>{
  let initParam={"appUrl":_cmsAppUrl,
"fileUpdPath":_cmsAppUrtPath};

return initParam;
}

export const appGetUrlPath=()=>{
  return _cmsAppUrtPath;
}
export const appGetMainUrl=()=>{
  return _cmsAppUrl;
}
export const appGetLoanUrlPath=()=>{
  return _cmsAppLoanUrtPath;
}

export const displaySteps=(obj,index,divClsName)=>{
  //alert($(obj).attr("type"));
 let current_fs = $(obj).parent();
 let next_fs = $(obj).parent().next();
 next_fs.show();
 current_fs.animate({opacity: 0}, {
  step: function(now) {
  // for making fielset appear animation
  let opacity = 1 - now;

  current_fs.css({
  'display': 'none',
  'position': 'relative'
  });
  next_fs.css({'opacity': opacity});
  },
  duration: 500
  });

  //alert($(divClsName).length);
  // var tabcontent = document.getElementsByClassName(divClsName);
  // for (var i = 0; i < tabcontent.length; i++) {
  //   tabcontent[i].style.display = "none";
  // }
  // if(index!=-1){
  //   tabcontent[index].style.display="block";
  // }
  
}

export const GetImgCertUserCMS_CBack=(obCls,frmNm,curl,callback)=>{
  ajaxindicatorstart("Processed Please Wait..");
  //alert(_cmsAppUrl+curl);
  var options={
    url     : _cmsAppUrl+curl,
    success : function(data)
    {
      ajaxindicatorstop();
      // alert(data);
      // $("#errLog").html(data);
      // return;
      //var resData=data.documentElement.childNodes[0].nodeValue;
     var resData=data;
      var checkData=$($($.parseHTML(resData)).filter("#resP")).html();
     var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).html();

     //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
     var userMsg="";  
     return callback(checkData,checkMsg,userMsg);
        
 },
    error: function(data) {
      ajaxindicatorstop();
       return callback(0,JSON.stringify(data),"0");
     }
   };
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="2px solid rgb(195 17 17)";
       isValidated=false;
     }
   }
   if(!isValidated){
    ajaxindicatorstop();
     return callback(-10,"Fill Required Fileds","");
   }else{
    //  alert(frmNm);
    $(frmNm).ajaxSubmit( options );
   }

 //$(frmNm).ajaxSubmit( options );

}

export const GetImgCertUserCMS=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : _cmsAppUrl+curl,
      success : function(data)
      {
        // alert(data);
        //var resData=data.documentElement.childNodes[0].nodeValue;
       var resData=data;
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";  
       return obCls[resfun](checkData,checkMsg,userMsg);
          
   },
      error: function(data) {
      //alert(JSON.stringify(data));
         return obCls[resfun](0,data);
       }
     };
   /* for validation control */
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="1px solid #e87d1e";
       isValidated=false;
     }
   }
   if(!isValidated){
     return obCls[resfun]("not_validated","","");
   }else{
    //  alert(frmNm);
    $(frmNm).ajaxSubmit( options );
   }
}


export const AjaxCallMethodCMS = (obCls,cBack)=>{

  $("._previousField").click(function(){
   let current_fs = $(this).parent();
   let previous_fs = $(this).parent().prev();
    previous_fs.show();
    current_fs.animate({opacity: 0}, {
      step: function(now) {
      // for making fielset appear animation
      let opacity = 1 - now;

      current_fs.css({
      'display': 'none',
      'position': 'relative'
      });
      previous_fs.css({'opacity': opacity});
      },
      duration: 500
      });

  });

  $("._nextField").click(function(){
    let current_fs = $(this).parent();
 let next_fs = $(this).parent().next();
 next_fs.show();
 current_fs.animate({opacity: 0}, {
  step: function(now) {
  // for making fielset appear animation
  let opacity = 1 - now;

  current_fs.css({
  'display': 'none',
  'position': 'relative'
  });
  next_fs.css({'opacity': opacity});
  },
  duration: 500
  });
  });
  

  $('._brFile').click(function(){
     var getNm=$(this).attr("setTo");
     
     document.getElementById(getNm).click();
     });
  $(".updImg").change(function(){
     //readURL(this);
     //alert("A file has been selected.");
 });
 $(".select_target").change(function () {
  var getCallFunc= $(this).attr("resp_dest");
  var refId=$(this).val();
  return obCls[getCallFunc](refId);
 });
 

   $('._btnSetColor').click(function () {
     //var m_type=$("#m_type").val();
   //var curl_type=$("#curl_type").val();
 //$("#modal_login").serialize();
  var ssFrm=$(this).closest('form');
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $(this).closest('form').serializeArray();
   
   //var xs = "{objs:" + JSON.stringify(ss) + "}";
   var xs=ss;
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
    /* Validation */
    let frmName=ssFrm.attr("id");
    // alert(typeof ssFrm);
    let objElems=document.getElementById(frmName).getElementsByClassName("required");
    let isValidated=true;
    // alert(objElems.length);
    for(var r=0;r<objElems.length;r++){
      if(objElems[r].value==="" || objElems[r].value==="0"){
        objElems[r].style.border="1px solid #e87d1e";
        isValidated=false;
      }
    }
   if(!isValidated){
     return obCls[getCallFunc]("not_validated","Fill blank records!"); 
   }
  // alert(isValidated); 
  // alert(xs);
  
   //ajaxindicatorstart('loading data.. please wait..');
//alert(getCallFunc);
  //var ss=$("#"+fName).serialize();
        $.ajax({
          crossDomain: true,
               type: "POST",
            url: _cmsAppUrl + curl_type,
            data: xs,
             success: function (data) {
                // alert(data);
                //ajaxindicatorstop();
                   //alert("Registration Completed Successfully ");
                   // document.getElementById("frmRegister").reset();
                   //location.reload();
                    if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
                   
                
               }
           });

});
}
export const getContents=(listTree,parentId,enc_id="")=>{
  const dataFilt=d => d.parent_id === parentId && d.col6 !== 'menu';
  var dataList=listTree.filter(dataFilt);
  var strData="";
  
  for(var i=0;i<dataList.length;i++){
    let editIcon="<a class=\"change-color2 _setbackparam\" data-toggle=\"modal\" data-target=\"#divEditHeader\" resback=\"getHeaderEditData\" param1=\""+dataList[i]["id"]+"\" href=\"javascript:void(0)\" m_type=\"addNewContent/"+dataList[i]["enc_id"]+"\" data-toggle=\"model\" data-original-title=\"Edit\"><span class=\"color-edit-content\">Edit<i class=\"fa fa-pencil text-inverse m-r-10 \"></i></span></a>";
    let deleIcon="<a href=\"#\" m_type=\"dele_fun\" mvcval=\""+dataList[i]["enc_id"]+"\" data-toggle=\"tooltip\" class=\"_btnSetColor\" data-original-title=\"Delete\"><i class=\"fa fa-window-close text-danger\"></i></a>";
    strData+="<li><a href=\"javascript:void(0)\" class=\"tgocm _setbackparam\" resback=\"getParamData\" param1=\""+dataList[i]["enc_id"]+"\" param2=\""+enc_id+"\">"+dataList[i]["col8"]+"-"+dataList[i]["col1"]+"</a>"+"|"+editIcon+"|"+""+"</li>";
  }
  if(dataList.length!=0){
   strData="<ul>"+strData+"</ul>"; 
  }
  return strData;
  }
export const getTreeMenu=(listTree)=>{
  // alert("i m 2");
   var getMenu = "";
   var getChMenu="";
  var jsTree=JSON.parse(listTree);
  const listFltr = d =>  d.parent_id == 0;
        var topMenusList = jsTree.filter(listFltr);
        //getChMenu += "<li><span class=\"caret treeview\">"+"Top Head"+"</span><span class=\"mdi mdi-plus-box-outline\"><a m_type=\"addNewContent?node="+"0"+"\" href=\"javascript:void(0);\" class=\"change-color\">New Header</a></span>";
        for (var i = 0; i < topMenusList.length; i++) {
          let editIcon="<a class=\"_setbackparam\" data-toggle=\"modal\" data-target=\"#divEditHeader\" resback=\"getHeaderEditData\" param1=\""+topMenusList[i]["id"]+"\" href=\"javascript:void(0)\" m_type=\"addNewContent/"+topMenusList[i]["enc_id"]+"\" data-original-title=\"Edit\"><span class=\"color-edit\">Edit<i class=\"fa fa-pencil text-inverse m-r-10 \"></i></span></a>";
          let deleIcon="<a href=\"#\" m_type=\"dele_fun\" mvcval=\""+topMenusList[i]["enc_id"]+"\" data-toggle=\"tooltip\" class=\"_setbackparam\" data-original-title=\"Delete\"><i class=\"fa fa-window-close text-danger\"></i></a>";
          const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.col6 === 'menu'
          var childMenuList = jsTree.filter(childFltr);
           getChMenu += "<li>"+""+"<span class=\"caret treeview\"><b>"+topMenusList[i]["col8"]+"-"+topMenusList[i]["col1"]+"</b></span>|<span class=\"mdi mdi-plus-box-outline\"><a class=\"_setbackparam\" resback=\"setExMenuId\"  param1=\""+topMenusList[i]["id"]+"\" data-toggle=\"modal\" data-target=\"#divAddHeader\" m_type=\"addNewContent?node="+topMenusList[i]["enc_id"]+"\" href=\"javascript:void(0);\" class=\"change-color\"><span class=\"color-addnew\">New Header</span></a>"+"|"+editIcon+"|"+""+"</span>";
           getChMenu+=getContents(jsTree,topMenusList[i]["id"],topMenusList[i]["enc_id"]);

           if(childMenuList.length!=0){
               getChMenu+="<ul class=\"nested\">";
           }
           for(var j=0;j<childMenuList.length;j++){
             //second level menu
            editIcon="<a class=\"change-color2\" href=\"javascript:void(0)\" m_type=\"addNewContent/"+childMenuList[j]["enc_id"]+"\" data-toggle=\"model\" data-original-title=\"Edit\"><i class=\"fa fa-pencil-alt text-inverse m-r-10 \"></i></a>";
              getChMenu+="<li><span class=\"caret treeview\">"+childMenuList[j]["col1"]+"</span><span class=\"mdi mdi-plus-box-outline\"><a m_type=\"addNewContent?node="+childMenuList[j]["enc_id"]+"\" href=\"javascript:void(0);\" class=\"change-color\">New Header</a>"+"|"+editIcon+"|"+"</span>";
              const child2Flts=d => d.parent_id === childMenuList[j]["id"] && d.col6 === 'menu'
              var Child2MenuList=jsTree.filter(child2Flts);
               getChMenu+=getContents(jsTree,childMenuList[j]["id"],childMenuList[j]["enc_id"]);
               if(Child2MenuList.length!=0){
               getChMenu+="<ul class=\"nested\">";
               }
              for(var k=0;k<Child2MenuList.length;k++){
                getChMenu+="<li><span class=\"caret treeview\">"+Child2MenuList[k]["col1"]+"</span><span class=\"mdi mdi-plus-box-outline\"><a m_type=\"addNewContent?node="+Child2MenuList[k]["enc_id"]+"\" href=\"javascript:void(0);\" class=\"change-color\">New Header</a></span>";
                getChMenu+=getContents(jsTree,Child2MenuList[k]["id"],Child2MenuList[k]["enc_id"]);
               
              }
               if(Child2MenuList.length!=0){
                 getChMenu+="</ul>";
              }
              getChMenu+="</li>";
           }
            if(childMenuList.length!=0){
             // alert(getChMenu);
            getChMenu+="</ul>";
          }
          getChMenu+="</li>";
        }
        return getChMenu;
}

export const btnSetColorCMS_CBack=(obCls,frmNm,curl,callback)=>{
  /* Validation */
  let frmName=frmNm;
  // alert(typeof ssFrm);
  let objElems=document.getElementById(frmName).getElementsByClassName("required");
  let isValidated=true;
  // alert(objElems.length);
  for(var r=0;r<objElems.length;r++){
    if(objElems[r].value==="" || objElems[r].value==="0"){
      objElems[r].style.border="1px solid #e87d1e";
      isValidated=false;
    }
  }
 if(!isValidated){
   return callback("not_validated","Fill blank records!"); 
 }

 //value objects
 ajaxindicatorstart("Loading Please Wait..");
var ssFrm=$("#"+frmNm);
var m_type= $(ssFrm).find('input[name="m_type"]').val();
var curl_type=$(ssFrm).find('input[name="m_type"]').val();
var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
 var ss = $("#"+frmNm).serializeArray();
 
 //var xs = "{'objs':" + JSON.stringify(ss) + "}";
 var xs=ss;
 //alert(xs);
if(!getCallFunc){
getCallFunc="";

 }
 $.ajax({
  crossDomain: true,
      type: "POST",
   url: _cmsAppUrl + curl_type,
   data: xs,
   //contentType: "application/json;charset=utf-8",
   //dataType: "json",
   success: function (data) {
     ajaxindicatorstop();
      //alert(data);
       //ajaxindicatorstop();
          //alert("Registration Completed Successfully ");
          // document.getElementById("frmRegister").reset();
          //location.reload();
           if(getCallFunc!="")
           {
              var checkData=$($($.parseHTML(data)).filter("#resP")).text();
              var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
        //  eval(getCallFunc);
        //alert(checkData);
              return callback(checkData,checkMsg);
           }else
           {
             //parent.$.colorbox.close();
          }
      }
  });

}
export function isJSONParsable(str) {
  try {
      JSON.parse(str);
      return true;
  } catch (e) {
      return false;
  }
}
export const CallCMSMapMethod_CBack=(obCls,frmValues,callback)=>{
  var curl_type=frmValues['curl_type'];

  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }

  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      let xs=ss;
      //var xs = "{'objs':" + JSON.stringify(ss) + "}";
     //alert(xs);
    
     //alert(_cmsAppUrl+curl_type);
     ajaxindicatorstart("Loading Please Wait..");
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: _cmsAppUrl+curl_type,
              data:xs,
             // contentType: "application/json;charset=utf-8",
              dataType: "json",
              complete: function (data) {
            ajaxindicatorstop();
            // alert(JSON.stringify(data));
            // return;
           // let respData=JSON.parse(data);
                //alert(JSON.stringify(data));
               //ajaxindicatorstop();
               // alert(data.d);
               // return data;0
             //alert(JSON.parse(data["responseText"])["data"]);
                if(isJSONParsable(data["responseText"])){
                  let pData=JSON.parse(data["responseText"])["data"];
                  let pMsg=JSON.parse(data["responseText"])["msg"];
                  //alert(JSON.stringify(pData));
                  
                    if(getCallFunc!="")
                    {
                       callback(JSON.stringify(pData),pMsg);
                    }
                }else{
                  
  var checkData=$($($.parseHTML(data["responseText"])).filter("#resP")).text();
  var checkMsg=$($($.parseHTML(data["responseText"])).filter("#resPS")).text();
  callback(checkData,checkMsg);
                }
             
                 
              }
          });
}
export const RespBkMthdMappCMS=(obCls,htCls,cBack,groupwhen="")=>{
  if(groupwhen==="2"){
    $("."+htCls).click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId);
   });
  }
  if(htCls==="custom-file-input"){
    //alert("I am in conidtion");
    $(".custom-file-input").change(function(){
      let fileName = $(this).val();
      let fileLable=$(this).attr("label");
      alert(fileLable);
      $("#"+fileLable).html(fileName);
    })
  }
  if(htCls==="filebr_name"){
   // $('#customFile').on('change', function(){ files = $(this)[0].files; name = ''; for(var i = 0; i < files.length; i++){ name += '\"' + files[i].name + '\"' + (i != files.length-1 ? ", " : ""); } $(".custom-file-label").html(name); });
   
  }
  if(htCls==="_setbackparam"){
    $("._setbackparam").unbind('click');
    $("._setbackparam").click(function(){
      var refId=$(this).attr("param1");
      var refId2=$(this).attr("param2");
      let clkObject=$(this)[0];
      if(typeof refId2!=="undefined" && typeof refId2!==false){
        
      }else{
        refId2="0";
      }

      var resBack=$(this).attr("resback");
      if(typeof resBack!=="undefined" && typeof resBack!==false){
        return obCls[resBack](refId,refId2,clkObject);
      }else{
        return obCls[cBack](refId,refId2,clkObject);
      }
      
   });
  }
  if(htCls==="select_file_ch"){
    $(".select_file_ch").change(function () {
    
     var nmFile= $(this).attr("param1");
     var URL = window.URL || window.webkitURL;
       var vidFile=this.files[0];
       var fileURL = URL.createObjectURL(vidFile);
      document.getElementById(nmFile).src=fileURL;
    });
  }
  if(htCls==="_setSearchKeyUp"){
   
    $("._setSearchKeyUp").keyup(function(e){
      var refId= $(this).val();
         return obCls[cBack](refId);
    });
  }
 if(htCls==="hid_file_ch"){
  var tabcontent = document.getElementsByClassName(htCls);
  return tabcontent;
  /*
  for(var t=0;t<tabcontent.length;t++){
   //alert("testing=="+tabcontent[t].value);
   var fileAttr=tabcontent[t].getAttribute("param1");
   return fileAttr;
   //alert(fileAttr+"=="+tabcontent[t].value);
   //document.getElementById(fileAttr).value=tabcontent[t].value;
  }
  */
 }else if(htCls="_addToCart"){
  $('.'+htCls).click(function(){
     //alert("I am in click");

     let ssFrm=$(this).closest('form');
     let getItem= localStorage.getItem("_cart");
     var items=[];
     if(getItem!=null && getItem!==""){
        getItem=JSON.parse(localStorage.getItem("_cart"));
     }else{
        getItem=[];
     }

     var itemName=$(ssFrm).find('input[name="item_name"]').val();
     var itemCode=$(ssFrm).find('input[name="item_code"]').val();
     var itemRate=$(ssFrm).find('input[name="item_rate"]').val();
     var compName=$(ssFrm).find('input[name="comp_name"]').val();
     var itemRfId=$(ssFrm).find('input[name="item_rf_id"]').val()
     var imgPath=$(ssFrm).find('input[name="imgs"]').val();
    
     items.push({"item_name":itemName,"item_code":itemCode,
     "item_rate":itemRate,"company_name":compName,"item_rf_id":itemRfId,"img_c":imgPath});
     for(var i=0;i<getItem.length;i++){
       //alert(getItem[i].item_name);
       items.push({"item_name":getItem[i].item_name,"item_code":getItem[i].item_code,
       "item_rate":getItem[i].item_rate,"company_name":getItem[i].company_name,"item_rf_id":getItem[i].item_rf_id,"img_c":getItem[i].img_c});
     }
    
     localStorage.setItem("_cart",JSON.stringify(items));
  
    if(cBack!=="nn" && cBack!=""){
     obCls[cBack](items.length);
    }
    });
} 
}
export const CallCMSMapMethod=(obCls,frmValues)=>{
   
    var curl_type=frmValues['curl_type'];
 
     //alert("check url == "+curl_type);
     var divRes="";
     var getCallFunc=""
     if(frmValues['res_name'])
     {
       divRes=frmValues['res_name'];
     }
     if(frmValues['res_func'])
     {
       getCallFunc=frmValues['res_func'];
     }
         var ss=frmValues;
         //var xs = "{objs:" + JSON.stringify(ss) + "}";
        //alert(xs);
          $.ajax({
             crossDomain:true,
                 type: "POST",
                 url: _cmsAppUrl+curl_type,
                 data:ss,
              success: function (data) {
                  //alert(data);   
               //ajaxindicatorstop();
                  // return data;
                  if(divRes!="")
                  {
                      if ($("#" + divRes).length!=0)
                   $("#"+divRes).html(data);
                  }
                  if(getCallFunc!="")
                  {
                   var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                   var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                   //  eval(getCallFunc);
                  // alert(checkMsg);
                  return obCls[getCallFunc](checkData,checkMsg);
                     }
                    
                 }
             });
 }
export const getRoleDetails=(roles,uriPath)=>{
  const listFltr = d => d.col2 === uriPath && d.status==='side';
  var topMenusList = roles.filter(listFltr);
  var listFilter;
  for(var i=0;i<topMenusList.length;i++){
    const childFltr = d => d.parent_id === topMenusList[i]["parent_id"] && d.status === 'side';
    listFilter = roles.filter(childFltr);
  }
  return listFilter;
}
export const getAccessRolesNew=(roles)=>{

    var getMenu="";
    const listFltr = d => (d.col6 === 'menu' || d.col6 === 'top_menu') && d.parent_id == 0;
    var topMenusList = roles.filter(listFltr);
    //alert(topMenusList.length);
    for (var i = 0; i < topMenusList.length; i++) {
        const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.col6 !== 'desk'
        var childMenuList = roles.filter(childFltr);
        var getChMenu = "";
        //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
        if (childMenuList.length === 0) {
            getMenu += " <li>" +
                "<a href = \"/" + topMenusList[i]["col2"] + "\">" + topMenusList[i]["col1"] + "</a>" +
                "</li>";
        } else {
            getMenu += "<li class=\"dropdown\">" +
            "<a href=\"#\">"+topMenusList[i]["col1"]+" <i class=\"ion-ios-arrow-down\"></i></a>";
               
            getChMenu = "<ul class=\"sub-menu\">";
            for (var ch = 0; ch < childMenuList.length; ch++) {
                const child2Fltr=d => d.parent_id === childMenuList[ch]["id"] && d.col6 === 'menu';
                var child2MenuList=roles.filter(child2Fltr);
                
                if(child2MenuList.length!=0){
                  getChMenu += "<li class=\"dropdown position-static\"><a href=\"" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "<i class=\"ion-ios-arrow-right\"></i></a>";
                  getChMenu+="<ul class=\"sub-menu sub-menu-2\">";
                }else{
                  getChMenu += "<li><a href=\"/" + childMenuList[ch]["col2"] + "\">" + childMenuList[ch]["col1"] + "</a></li>";
                }
                for(var k=0;k<child2MenuList.length;k++){
                  getChMenu += "<li><a href=\"/" + child2MenuList[k]["col2"] + "\">" + child2MenuList[k]["col1"] + "</a></li>";
                }
                if(child2MenuList.length!=0){
                  getChMenu+="</ul></li>";
                }
            }
            getChMenu += "</ul>";
            getMenu += getChMenu + "</li>";
        }
  
        // getMenu += topMenusList[i]["col1"];
    }
    return getMenu;
   
  }
  export function ajaxindicatorstart(text) {
    if (jQuery('body').find('#resultLoading').attr('id') != 'resultLoading') {
        jQuery('body').append('<div id="resultLoading" style="display:none"><div><img src="../ajax-loader.gif"><div>' + text + '</div></div><div class="bg"></div></div>');
    }
  
    jQuery('#resultLoading').css({
        'width': '100%',
        'height': '100%',
        'position': 'fixed',
        'z-index': '10000000',
        'top': '0',
        'left': '0',
        'right': '0',
        'bottom': '0',
        'margin': 'auto'
    });
  
    jQuery('#resultLoading .bg').css({
        'background': '#000000',
        'opacity': '0.7',
        'width': '100%',
        'height': '100%',
        'position': 'fixed',
        'top': '0',
        'margin-left': '0'
    });
  
    jQuery('#resultLoading>div:first').css({
        'width': '250px',
        'height': '75px',
        'text-align': 'center',
        'position': 'fixed',
        'top': '0',
        'left': '0',
        'right': '0',
        'bottom': '0',
        'margin': 'auto',
        'font-size': '16px',
        'z-index': '99999999999',
        'color': '#ffffff'
  
    });
  
    jQuery('#resultLoading .bg').height('100%');
    jQuery('#resultLoading').fadeIn(300);
    jQuery('body').css('cursor', 'wait');
  }
  
  export function ajaxindicatorstop() {
    jQuery('#resultLoading .bg').height('100%');
    jQuery('#resultLoading').fadeOut(300);
    jQuery('body').css('cursor', 'default');
  }
  export default CallCMSMapMethod